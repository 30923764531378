import styled, { css, th } from '@xstyled/styled-components';

export const PageTitle = styled.h1Box`
  text-align: center;
  font-size: large;
  font-weight: bold;
  line-height: 1.3;
  color: #52c1d3;
  @media (min-width: desktop) {
    font-size: ${({ fontSize }) => fontSize ?? th.fontSize('2.5rem')};
  }
`;

export const SectionTitle = styled.h2Box`
  font-size: medium;
  font-weight: 700;
  line-height: 1.35;

  @media (min-width: desktop) {
    font-size: ${({ fontSize }) => fontSize ?? th.fontSize('xlarge')};
  }
`;

export const SectionTitleSmall = styled(SectionTitle)`
  font-weight: 500;

  @media (min-width: desktop) {
    font-size: medium;
  }
`;

export const CategoryTitle = styled.h3Box`
  font-size: small;
  font-weight: 700;
  line-height: 1.2;
  color: teal-300;
  text-transform: uppercase;

  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: small;

      span {
        margin-right: 0.5rem;
      }
    `}
`;
