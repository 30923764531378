import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useAtom } from 'jotai';
import { authModalAtom, resetPasswordModalAtom } from 'global/store';
import { REQUEST_PASSWORD_RESET, RESET_PASSWORD } from 'graphql/mutations';
import usePasswordErrors from 'hooks/auth/usePasswordErrors';
import { Box } from '@xstyled/styled-components';
import Modal from '@/UI/Modal';
import Input from '@/UI/Form/Input';
import { FlexBox, FlexColCenter } from '@/UI/Primitives/FlexBox';
import { SectionTitle } from '@/UI/Primitives/Headings';
import PrimaryButton from '@/UI/Button/Variants/Primary';
import ReturnButton from '@/UI/Modal/ReturnButton';
import Spinner from '@/UI/Loader/Spinner';

export default function ResetPassword() {
  const router = useRouter();
  const token = router.query?.token;

  const {
    register,
    unregister,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
    setError
  } = useForm();

  const [isOpen, setIsOpen] = useAtom(resetPasswordModalAtom);
  const [, setAuthModalOpen] = useAtom(authModalAtom);

  const [resetType, setResetType] = useState('request');
  const isRequest = resetType === 'request';

  const [requestPasswordReset, { loading: requestLoading }] = useMutation(
    REQUEST_PASSWORD_RESET
  );
  const [setNewPassword, { error, loading: newPasswordLoading }] =
    useMutation(RESET_PASSWORD);

  const onSubmit = async data => {
    if (isRequest) {
      await requestPasswordReset({ variables: { email: data.email } });
    } else {
      await setNewPassword({
        variables: {
          token,
          email: router.query?.email,
          newPassword: data.password
        }
      });
    }
  };

  useEffect(() => {
    if (token) {
      setResetType('new-password');
      unregister('email');
    }
  }, [token]);

  // Set password errors manually
  usePasswordErrors(error, setError);

  const modalContent = (
    <FlexColCenter as="form" onSubmit={handleSubmit(onSubmit)} pb="2rem">
      {isRequest && !isSubmitSuccessful && (
        <ReturnButton
          onClick={() => {
            setIsOpen(false);
            setAuthModalOpen(true);
          }}
        />
      )}
      <SectionTitle>
        {isRequest
          ? 'Parooli taastamine'
          : isSubmitSuccessful
          ? 'Uus parool'
          : 'Vali uus parool'}
      </SectionTitle>
      {!isSubmitSuccessful ? (
        <>
          {isRequest && (
            <Box as="span" color="#2A2A2A">
              Salasõna taastamiseks sisesta e-posti aadress
            </Box>
          )}
        </>
      ) : (
        <>
          <Box
            as="span"
            mt="1rem"
            maxW={280}
            textAlign="center"
            color="#2A2A2A"
          >
            {isRequest
              ? 'Teie e-posti aadressile on saadetud link salasõna taastamiseks'
              : 'Teie salasõna on muudetud'}
          </Box>
          <PrimaryButton
            h="3rem"
            w="30%"
            mt="2rem"
            onClick={() => {
              setIsOpen(false);
              // Reset form
              reset();
              // Clear query params
              router.replace('/', undefined, { shallow: true });
            }}
          >
            Ok
          </PrimaryButton>
        </>
      )}
      {!isSubmitSuccessful && (
        <FlexBox dir="column" w="80%" pt="2rem">
          {isRequest ? (
            <Input
              type="email"
              placeholder="E-post"
              {...register('email', {
                required: 'Kohustuslik väli'
              })}
              error={errors.email}
            />
          ) : (
            <Input
              type="password"
              placeholder="Uus parool"
              {...register('password', {
                required: 'Kohustuslik väli'
              })}
              error={errors.password}
            />
          )}
          <PrimaryButton
            type="submit"
            h="3rem"
            w="50%"
            mt="2rem"
            alignSelf="center"
          >
            {requestLoading || newPasswordLoading ? (
              <Spinner />
            ) : isRequest ? (
              'Saada'
            ) : (
              'Kinnita'
            )}
          </PrimaryButton>
        </FlexBox>
      )}
    </FlexColCenter>
  );

  return (
    <Modal
      hideClose
      isShown={isOpen}
      content={modalContent}
      width={{ _: '90vw', sm_tablet: 500, tablet: 590 }}
      p={{ _: '2.5rem 1rem', desktop: '2.5rem 4rem 1rem' }}
      h={{ _: '50vh', desktop: '100%' }}
    />
  );
}
