import { Fragment, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from '@xstyled/styled-components';
import useLockBodyScroll from 'hooks/UI/useLockBodyScroll';
import { Close } from '@/UI/Icons';

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #3d4d4f;
  opacity: 0.6;
  z-index: 500;
`;

export const ModalWrapper = styled.divBox`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 700;
`;

export const ModalContent = styled.divBox`
  position: relative;
  margin: auto;
  border-radius: 22px;
  background-color: white;
  z-index: 100;
  overflow-y: auto;

  span.closeModal {
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    cursor: pointer;
    z-index: 101;

    @media (max-width: desktop) {
      top: 1rem;
      right: 1rem;
    }

    svg {
      @media (min-width: desktop) {
        transform: scale(1.2);
      }
    }
  }
`;

export default function Modal({
  isShown,
  hide,
  content,
  width,
  hideClose,
  ...rest
}) {
  useLockBodyScroll(isShown);

  useEffect(() => {
    const onKeyDown = e => {
      if (e.keyCode === 27 && isShown) {
        hide();
      }
    };

    document.addEventListener('keydown', onKeyDown, false);
    return () => {
      document.removeEventListener('keydown', onKeyDown, false);
    };
  }, [isShown, hide]);

  const modal = (
    <Fragment>
      <Backdrop onClick={hide} />
      <ModalWrapper aria-modal tabIndex={-1} role="dialog" w={width}>
        <ModalContent {...rest}>
          {!hideClose && (
            <span
              role="button"
              aria-label="Close modal"
              className="closeModal"
              onClick={hide}
            >
              <Close />
            </span>
          )}
          {content}
        </ModalContent>
      </ModalWrapper>
    </Fragment>
  );

  return isShown ? createPortal(modal, document.body) : null;
}
