import { useEffect } from 'react';

export default function usePasswordErrors(error, setError) {
  const setPasswordError = message =>
    setError('password', {
      type: 'manual',
      message
    });

  // Validate password length and classes of characters used (errors from Magento)
  useEffect(() => {
    if (error) {
      if (error.message.includes('at least 8 characters')) {
        setPasswordError('Parool peab sisaldama vähemalt 8 tähemärki');
      }
      if (error.message.includes('different classes of characters')) {
        setPasswordError('Parool peab sisaldama suuri tähti ja numbreid');
      }
    }
  }, [error]);
}
