import styled, { css, th } from '@xstyled/styled-components';

export const FlexCenter = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CrossOut = css`
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-top: calc(0.125rem / 2 * -1);
  border-bottom: 0.125rem solid ${th.color('blue-200')};
`;

export const HideInput = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  clip-path: inset(50%);
  white-space: nowrap;
`;

export const ErrorMessage = styled.spanBox`
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
  padding-right: 0.25rem;
  color: #e54f4f;
  font-size: 14px;
`;
