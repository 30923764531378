import Button from '..';

/**
 * PrimaryButton
 *
 * The main button component
 *
 * @prop {ReactNode | ReactNodeArray} children - button content
 */

export default function PrimaryButton({ children, ...rest }) {
  return (
    <Button bg="teal-100" hoverBg="teal-300" color="white" {...rest}>
      {children}
    </Button>
  );
}
