import { Box } from '@xstyled/styled-components';

export default function ReturnButton({ onClick }) {
  return (
    <Box
      as="span"
      role="button"
      onClick={onClick}
      aria-label="Return from modal"
      position="absolute"
      left="2rem"
      top="3rem"
      cursor="pointer"
    >
      <Arrow />
    </Box>
  );
}

const Arrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <g
        id="Group_1381"
        data-name="Group 1381"
        transform="translate(-692 -235)"
      >
        <g id="Group_218" data-name="Group 218" transform="translate(-498 13)">
          <circle
            id="Ellipse_24"
            data-name="Ellipse 24"
            cx="14"
            cy="14"
            r="14"
            transform="translate(1190 222)"
            fill="#efefef"
          />
        </g>
        <path
          id="Path_421"
          data-name="Path 421"
          d="M9609.712,6339.933H9595.16"
          transform="translate(-8896.436 -6091)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          id="Path_422"
          data-name="Path 422"
          d="M0,5.577,5.578,0l5.577,5.577"
          transform="translate(698.725 254.51) rotate(-90)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="bevel"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
