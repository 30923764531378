import { forwardRef } from 'react';
import { Box } from '@xstyled/styled-components';
import { ErrorMessage } from '@/UI/utils';

const Input = forwardRef((props, ref) => (
  <Box>
    <Box
      ref={ref}
      as="input"
      type={props.type || 'text'}
      placeholder={props.placeholder}
      disabled={props.disabled}
      w="100%"
      p="12px"
      border="0.8px solid #a8a9ba"
      borderRadius="7px"
      borderColor={props.error && '#e54f4f'}
      hoverBorderColor="gray"
      focusBorder="1.6px solid gray"
      focusOutline={0}
      disabledBackgroundColor="#F9F9F9"
      {...props}
    />
    {props.error && <ErrorMessage>{props.error.message}</ErrorMessage>}
  </Box>
));

Input.displayName = 'Input';

export default Input;
