import styled from '@xstyled/styled-components';

const SpinnerLoader = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  margin: 0 auto;
  animation: chase 2.5s infinite linear both;

  .dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: chase-dot 2s infinite ease-in-out both;
  }

  .dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background: ${({ color }) => color ?? '#fff'};
    border-radius: 100%;
    animation: chase-dot-before 2s infinite ease-in-out both;
  }

  .dot:nth-child(1) {
    animation-delay: -1.1s;
  }
  .dot:nth-child(2) {
    animation-delay: -1s;
  }
  .dot:nth-child(3) {
    animation-delay: -0.9s;
  }
  .dot:nth-child(4) {
    animation-delay: -0.8s;
  }
  .dot:nth-child(5) {
    animation-delay: -0.7s;
  }
  .dot:nth-child(6) {
    animation-delay: -0.6s;
  }
  .dot:nth-child(1):before {
    animation-delay: -1.1s;
  }
  .dot:nth-child(2):before {
    animation-delay: -1s;
  }
  .dot:nth-child(3):before {
    animation-delay: -0.9s;
  }
  .dot:nth-child(4):before {
    animation-delay: -0.8s;
  }
  .dot:nth-child(5):before {
    animation-delay: -0.7s;
  }
  .dot:nth-child(6):before {
    animation-delay: -0.6s;
  }

  @keyframes chase {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes chase-dot {
    80%,
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes chase-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%,
    0% {
      transform: scale(1);
    }
  }
`;

export default function Spinner({ color }) {
  return (
    <SpinnerLoader color={color}>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </SpinnerLoader>
  );
}
